import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useStudentsBillingStore } from "../../../../Store/CashControlStore/StudentsBillingStore";
import { useCallback } from "react";

export default function FormControlLabelPosition() {
  const {
    loadItems,
    setLoading,
    checkbox_filterset,
    updateParams,
    queryParams,
  } = useStudentsBillingStore();

  const handleChange = useCallback(
    async (e) => {
      setLoading(true);
      updateParams({
        debting_month:
          e.target.value == queryParams.debting_month ? "" : e.target.value,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          debting_month:
            e.target.value == queryParams.debting_month ? "" : e.target.value,
          page: 1,
        }).toString()
      );
      setLoading(false);
    },
    [queryParams]
  );

  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <FormGroup
        row
        aria-label="position"
        sx={{
          fontSize: { xs: "8px", sm: "10px", md: "12px" },
          flexDirection: { xs: "row" },
          gap: 1,
        }}
      >
        {checkbox_filterset?.map((element) => (
          <FormControlLabel
            key={element.value}
            value={element.value}
            onChange={handleChange}
            control={
              <Checkbox
                size="small"
                checked={queryParams.debting_month == element.value}
              />
            }
            label={element.name}
            labelPlacement="end"
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
