import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "uz",
  lng: "uz",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    uz: {
      translation: {
        home_config: "Sozlamalar",
        home_registration: "Ro'yxatga olish",
        home_groups_manage: "Guruhlarni boshqarish",
        home_groups_statistics: "Guruhlar statistikasi",
        home_cash_config: "Kassani nazorat qilish",
        home_reports_module: "Hisobotlar bo'limi",
        home_courses: "Kurslar",
        home_branches: "Bino va Filiallar",
        home_teachers: "O'qituvchilar",
        home_students: "O'quvchilar",
        home_groups: "Guruhlar",
        home_exams: "Imtixonlar jadvali",
        home_student_transfer: "O'quvchini boshqa guruhga olib o'tish",
        home_student_transfer_list: "Boshqa guruhga o'tganlar ro'yxati",
        home_groups_level_up: "Guruhlarni tartiblanish jadvali",
        home_statistics_by_age_group:
          "Yosh turiga ko'ra talabalar statistikasi",
        home_acquisition_by_age_group:
          "Yosh turiga ko'ra talabalar o'zlashtirishi",
        home_statistics_by_lesson_month:
          "Guruhlar darajasiga ko'ra statistikasi",
        home_rooms_availability: "Bino va xonalar bandlik jadvali",
        home_teachers_availability: "O'qituvchilarning bandlik jadvali",
        home_icome_cash: "To'lovlar",
        home_icome_cash_history: "To'lovlar tarixi",
        home_list_of_students_in_debt: "Qarzdor o'quvchilar ro'yxati",
        home_expenses: "Xarajatlar",
        home_list_of_expenses: "Xarajatlar ro'yxati",
        home_list_of_salary: "Ish xaqqi va avans berish ro'yxati",
        home_paying_employees: "Xodimlarga ish xaqqi ishlash",
        home_all_reports: "Barcha hisobotlar",
        home_income_reports: "Tushum hisobotlar",
        home_tasks: "Topshiriqlar",
        header_profile: "Profil",
        header_settings: "Sozlamalar",
        header_exit: "Chiqish",
        reports_header: "Hisobotlar bo'limi",
        reception_reports: "Qabulxona hisobotlari",
        discount_reports: "Chegirma olganlar",
        excluded_from_group: "Guruhdan chiqarilganlar",
        missed_class: "Dars qoldirgan talabalar",
        active_passive_readers: "Faol va passiv o'quvchilar ro'yxati",
        exam_failed_students: "Imtixondan o'ta olmagan o'quvchilar ro'yxati",
        income_reports: "Tushum hisobotlari",
        staff_statistics: "Xodimlar statistikasi",
        reports_with_agegroup: "Guruhlarning yosh turi bo'yicha hisoboti",
        finished_students: "Kursni tamomlagan o'quvchilar",
        monthly_reports: "Oylik tushum hisobotlar",
        daily_reports: "Kunlik tushum hisobotlar",
        weekly_reports: "Haftalik tushum hisobotlar",
        expenditure_reports: "Xarajatlar hisoboti",
        SMS: "SMS xabarnoma",
        others: "Boshqalar",
        textbooks: "O'quv qo'llanmalar",
        // config-template
        config_template_title: "Dars turi va vaqtini kiritish",
        add_btn: "Kiritish",
        branch: "Filiallar",
        subject: "Fanlar",
        group_type: "Guruh turlari",
        lesson_plan: "Haftalik darslar rejasi",
        course_duration: "Dastur davomiyligi",
        lesson_count: "Darslar soni",
        enter_course: "Dars soatlari va turlarni kiritish",
        enter_payment: "Oylik to'lovlarni kiritish",
        payment_month: "To'lov oyi",
        payment_price: "Oylik to'lov summasi",
        required_subject: "Fan tanlanishi shart",
        required_branch: "Filial tanlanishi shart",
        required_group: "Guruh turini tanlang",
        required_course_duration: "Umumiy dastur davomiyligini kiriting",
        required_lesson_plan: "Bir haftalik darslar sonini kiriting",
        only_number: "Raqam kiriting",
        only_string: "Matn kiriting",
        required_month: "Oyni kiriting",
        required_month_price: "Oylik tolovni kiriting",
        success_open_course: "Yangi kurs ochildi",
        success_open_course: "Yangi kurs ochildi",
        months: "Oylar",
        months_payment: "Oylik to'lovlar",
        payment_month_amount: "Oylik to'lovlar summasi",
        delete: "O'chirish",
        cancel: "Bekor qilish",
        submit: "Saqlash",
        edit: "Taxrirlash",
        confirm: "Tasdiqlash",
        agree_delete: "Tanlangan ma'lumot o'chirilishiga rozimisiz?",
        // Branches
        branches_title: "Bino va Filiallarni kiritish",
        branch_name: "Markaz va filiallar nomi",
        branch_address: "Joylashgan manzili",
        room_count: "Мavjud o'quv xonalar soni",
        short_name: "Qisqacha nomi yoki nik",
        created_date: "Kiritilgan sanasi",
        branch_rooms: "Binodagi xonalar soni",
        room_number: "Xona raqami",
        room_name: "Xona nomi",
        room_teacher: "Mas'ul hodim",
        is_lesson_room: "Dars xonasi",
        yes: "Ha",
        no: "Yo'q",
        required_address: "Manzilni kiriting!",
        address_limit: "Manzil nomi 200 ta belgidan kam bo'lishi kerak!",
        required_branch_name: "Bino nomini kiriting",
        branch_name_limit: "Bino nomi 100 ta belgidan kam bo'lishi kerak!",
        required_short_nbme: "Qisqacha nomini kiriting!",
        short_name_limit: "Qisqacha nomi 50 ta belgidan kam bolishi kerak!",
        required_branch_room_count: "Binodagi xonalar sonini kiriting!",
        required_room_name: "Xona nomini kirish shart!",
        required_staff: "Xodimni biriktirish shart!",
        success_brach_add: "Bino kiritildi",
        success_branch_update: "Bino turi yangilandi!",
        address_placeholder: "Shaxar, ko'cha, uy",
        branch_name_placeholder: "Birinchi bosh amaliyot markazi",
        short_name_placeholder: "Ismi, nik",
        // Staffs
        staff_title: "O'qituvchi va xodimlarni ro'yxatdan o'tkazish",
        thead_title: "Xodimning F.I.O",
        thead_id: "ID-kodi",
      },
    },
    en: {
      translation: {
        Fan: "Subject",
        "topshiriq qo'shish": "Add task",
        Darslar: "Lessons",
        "Vaqtni belgilang": "Set time",
        Xodimlar: "Employees",
        Muddati: "Deadline",
        Vaqti: "Time",
        Joylash: "Place",
        "Direktor qabul qildi": "Accepted by the director",
        "Batafsil izoh": "Detailed explanation",
        "Topshiriq nomi": "Task name",
        "Topshiriq holati": "Task status",
        "Topshiriqni kiritish": "Enter task",
        "Topshiriqni joylash": "Place task",
        "Topshiriq o'chirildi": "Task deleted",
        "Xodim tanlansin": "Select an employee",
        "Muddatini belgilang": "Set deadline",
        "O'chirish": "Delete",
        "Qo'shish": "Add",
        "Topshiriq holati": "Task status",
        "Topshiriq muddati": "Task deadline",
        "Topshiriq o'chirildi": "Task deleted",
        "Masul xodim": "Responsible employee",
        "Kiritilgan vaqti": "Time entered",
        Topshiriq: "Task",
        "O'chirish uchun ikki marta bosing": "Double-click to delete",
        "Oylik darslar": "Monthly lessons",
        "Bajaruvchisi belgilanmagan vazifalar": "Unassigned tasks",
        "Dars yuklamalari": "Lesson workloads",
        "Darslik havolasi": "Textbook link",
        "Dars o'tish uchun o'quv qo'llanma": "Study guide for teaching",
        "Ingliz tilidan o'qitish metodikasi":
          "Methodology for teaching English",

        //Reports
        "Guruhdan chiqarilgan soni": "Number of Expelled from Group",
        "Imtihondan yiqilganlar": "Failed Exams",
        "Boshqa guruhi o'tkazilganlar": "Transferred to Another Group",
        "Darsga kelmaganlar": "Absent from Class",
        "Qabulga yozilganlar": "Enrolled in Admission",
        Balans: "Balance",
        "Top fanlar": "Top Subjects",
        "Kunlik Tushumlar": "Daily Revenue",
        "Kunlik Chiqimlar": "Daily Expenses",
        "Umumiy Foyda": "Total Profit",

        // Cash-control
        "Kirim kassa to‘lov oynasi": "Income cashier payment page",
        "Ismi, Familiyasi yoki ID kodni kiriting":
          "Enter name, surname or ID code",
        "O'quvchi ma'lumotlari": "Student information",
        "F.I.Sh": "Full name",
        Yoshi: "Age",
        yosh: "age",
        "Telefon raqami": "Phone number",
        "O'qiyotgan guruh": "Studying group",
        "To'lov oylari": "Payment months",
        "O’qiyotgan guruhi": "Studying group",
        "O‘qituvchisi": "Teacher",
        "Oldingi oylardan qarzi": "Debt from previous months",
        "Qarzi yo'q": "No debt",
        "Hozirgi o'qiyotgan oyi": "Current study month",
        "O’qiyotgan fani": "Studying subject",
        Oy: "Month",
        "Jami darslar soni": "Total number of classes",
        "Qatnashgan darslar soni": "Number of attended classes",
        "Qatnashgan darslar narxi": "Price of attended classes",
        "Sababli qoldirgan darslar soni": "Number of excused missed classes",
        "Sababli qoldirgan darslar narxi": "Price of excused missed classes",
        "Bitta dars narxi": "Price per class",
        "Ushbu oy narxi": "This month's price",
        "To'lanishi kerak bo'lgan narx": "Price to be paid",
        "Yakuniy qarzdorlikni belgilash": "Determine final debt",
        "To'lov miqdori": "Payment amount",
        "To'lovni amalga oshirish": "Make payment",
        "To'lov amalga oshirilsinmi?": "Proceed with payment?",
        "Guruhni tanlang!": "Select group!",
        "To'lovni kiriting!": "Enter payment!",
        "To'lov turini tanlang!": "Select payment type!",
        "Xodimlarga oylik ishlash": "Monthly work for employees",
        "Qidiruvni kiriting!": "Enter search!",
        Yil: "Year",
        "Jami xodimlar": "Total employees",
        "Jami oyliklar": "Total salaries",
        "Jami fixed bo'yicha": "Total fixed",
        "Jami KPI bo'yicha": "Total based on KPI",
        "Jami kiritilgan pullar": "Total entered funds",
        Avanslar: "Advances",
        "To'lanmagan": "Unpaid",
        "Doimiy chiqimlar": "Regular expenses",
        "Chiqim kassa xarajat oynasi": "Expense cashier window",
        "Avans/oylik": "Advance/salary",
        "O'quvchilarga pul qaytarib berish": "Refund students",
        "Chiqim turi, qiymati va to'lov turi kiritilishi shart!":
          "Expense type, amount and payment type must be entered!",
        "Chiqim turi kiritilishi shart!": "Expense type must be entered!",
        "Chiqim qiymati kiritilishi shart!": "Expense amount must be entered!",
        "To'lov turi kiritilishi shart!": "Payment type must be entered!",
        Kategoriya: "Category",
        "Chiqim turi": "Expense type",
        "Biriktirilgan xodim(ixtiyoriy)": "Assigned employee (optional)",
        "Chiqim amalga oshirilsinmi?": "Proceed with expense?",
        Tozalash: "Clear",
        "To'lovni tasdiqlaysizmi?": "Do you confirm the payment?",
        "To'lovlar tarixi": "Payment history",
        "Yakuniy ish haqi": "Final salary",
        "Belgilangan ish haqi": "Fixed salary",
        "Telefon raqami": "Phone number",
        "Xodim ID": "Employee ID",
        "Xodim ma'lumotlari": "Employee information",
        "To'lov turi": "Payment type",
        Qiymati: "Value",
        Kassir: "Cashier",
        "To'lov qilingan sana": "Payment date",
        "To'lov qiymatini va to'lov turi kiritilishi shart!":
          "Payment amount and payment type must be entered!",
        "To'lov qiymatini  kiritish shart!": "Payment amount must be entered!",
        "To'lov oyini tanlang!": "Select payment month!",
        "Oylik ishlangan": "Monthly earned",
        "Berilgan pul": "Given money",
        "Qolgan haqi": "Remaining due",
        Qarzdorligi: "Debt",
        "Yakuniy oyligi": "Final salary",
        "Jami kiritilgan pul": "Total entered funds",
        "Fixed oylik sifatida": "As fixed salary",
        "KPIga asoslangan oylik": "Salary based on KPI",
        Bonus: "Bonus",
        "Jarima va soliq": "Penalty and tax",
        "To'lov miqdori": "Payment amount",
        Qiymati: "Value",
        "To'lovlar mavjud emas!": "No payments available!",
        "Pul qaytarildi": "Money refunded",
        "To'lovni tasdiqlaysizmi?": "Do you confirm the payment?",
        "O'quvchilar": "Students",
        "Yashash manzili": "Address",
        "Haqdor guruhlari": "Eligible groups",
        Guruhi: "Group",
        "Oylik to'lov miqdori": "Monthly payment amount",
        "Jami to'langan": "Total paid",
        "Jami darslar soni": "Total number of classes",
        "Qatnashgan darslar soni": "Number of attended classes",
        "Qatnashgan darslar narxi": "Price of attended classes",
        "Ortiqcha to'lov": "Excess payment",
        "Haqdor guruhni, to'lov qiymatini va to'lov turi kiritilishi shart!":
          "Eligible group, payment amount and payment type must be entered!",
        "to'lov miqdorini kiritish shart!": "Payment amount must be entered!",
        "Ortiqcha mablag'ni kiritish shart!": "Excess amount must be entered!",
        "To'lov turi kiritilishi shart!": "Payment type must be entered!",
        "Xarajatlar ro'yxati": "Expense list",
        "Harajat turlari": "Expense types",
        Summasi: "Amount",
        "To'lov turi": "Payment type",
        Filiali: "Branch",
        Filial: "Branch",
        "Masul xodim": "Responsible employee",
        "Ushbu xarajatni bekor qilish": "Cancel this expense",
        "To'lovlarni faqat 1 kun ichida qaytarish mumkin":
          "Refunds are possible only within 1 day",
        "Xodimlarga ish haqqi va avans berish ro'yxati":
          "List of salaries and advances for employees",
        "Ushbu to'lovni bekor qilish": "Cancel this payment",
        "Xodimlarga oylik ishlash": "Monthly work for employees",
        "Ma'lumotlar saqlansinmi?": "Save data?",
        "Ismi va Famliyasi": "First and last name",
        "Jami kiritilgan pullar": "Total entered funds",
        "Fixed(O'zgarmas) oylik": "Fixed salary",
        "KPI foizi": "KPI percentage",
        "KPI asosida oylik": "Salary based on KPI",
        "Jami ish haqi": "Total salary",
        Avans: "Advance",
        "Bonus foizi": "Bonus percentage",
        "Jarima foizi": "Penalty percentage",
        "Yakuniy ish haqi": "Final salary",
        "vaqtinchalik saqlash": "Temporary storage",
        "Qarzdor o'quvchilar ro'yxati": "List of indebted students",
        "O'quvchi": "Student",
        "Nechichi oy qarz": "Debt for which month",
        "Oxirgi to'lov qilgan sana": "Last payment date",
        "Tel raqami": "Phone number",
        Sana: "Date",
        "To'lov rejimi": "Payment mode",
        "To'lov summasi": "Payment amount",
        "Oylik ishlash": "Monthly earnings",
        "Davom etish": "Continue",
        "Dars qoldirgan talabalar": "Students who missed classes",
        Status: "Status",
        "Guruhlarning yosh turi bo'yicha hisoboti": "Report by age group",
        "Xodimni tanlash!": "Select an employee!",
        "O'quv oyini tanlang!": "Select the study month!",
        "Guruhni tanlash!": "Select the group!",
        "Xodim qo'shish": "Add employee",
        "Imtixon turi": "Exam type",
        "Kunlik tushum hisobotlari": "Daily income reports",
        Naqd: "Cash",
        "Badal to'lov": "Membership fee",
        Plastik: "Plastic",
        Terminal: "Terminal",
        "Smart kartadan chegirma olganlar":
          "Those who received a discount from a smart card",
        "Oy ohridagi imtixondan olgan chegirmalar soni":
          "Number of discounts received from the end of month exam",
        "Chegirmalar sababli yo'qotilgan summalar":
          "Amounts lost due to discounts",
        "Kunlik to'lovlardan jami summasiga nisbatan o'rtacha to'lovlar":
          "Average payments relative to the total amount of daily payments",
        "To'lov nomlari": "Payment names",
        "Summa / Foiz": "Amount / Percentage",
        "To'lovlar": "Payments",
        "O'quvchilarga qaytarilgan pullar": "Refunds to students",
        Xarajatlar: "Expenses",
        "Ish haqi uchun": "For salary",
        "Jami xarajatlar": "Total expenses",
        "Jami xarajatlar foizi": "Total expenses percentage",
        "Jami foyda": "Total profit",
        "Jami foyda foizi": "Total profit percentage",
        "Chegirma olgan o'quvchilar": "Students who received a discount",
        "O'quvchi": "Student",
        "Chegirma turi": "Discount type",
        "Chegirma qiymati": "Discount value",
        "Imtixondan o'ta olmagan o'quvchilar ro'yxati":
          "List of students who failed the exam",
        "To'plagan bali": "Score achieved",
        "O'tish bali": "Passing score",
        "O'qituvchi": "Teacher",
        "Guruhdan chiqarilgan o'quvchilar": "Students expelled from the group",
        "Xarajatlar hisobotlari": "Expense reports",
        "Xarajatlar nomi": "Expense name",
        "Kursni muofaqqiyatli tugatgan o'quvchilar ro'yxati":
          "List of students who successfully completed the course",
        "Sertifikati borlar o'quvchilar": "Students with certificates",
        "IELTS natijasi": "IELTS score",
        "Oylik tushum hisobotlari": "Monthly income reports",
        "Yil boshiga nisbatan qoldiqlar":
          "Balances compared to the beginning of the year",
        "Qabulxona hisobotlar": "Reception reports",
        "Hafta kunlari": "Days of the week",
        Hisobotlar: "Reports",
        "Chegirma olgan o'quvchilar va chegirma qiymati":
          "Students who received discounts and discount value",
        "Faol va passive o'quvchilar ro'yxati":
          "List of active and passive students",
        Kardlar: "Cards",
        "Smart kartlar": "Smart cards",
        "Warning kartlar": "Warning cards",
        "Haftalik tushum hisobotlari": "Weekly income reports",
        "Avvalgi oydagi qoldiqlar": "Previous month's balances",
        Kunlar: "Days",
        "Naqt pul tushum": "Cash income",
        "Bank tushum": "Bank income",
        "Click tushum": "Click income",
        "Naqt pul chiqim": "Cash expense",
        "Bank chiqim": "Bank expense",
        "Click chiqim": "Click expense",
        "Naqt pul qoldi": "Cash balance",
        "Bank qoldi": "Bank balance",
        "Click qoldi": "Click balance",
        Chegirmalar: "Discounts",
        "Chegirma to'lovlari": "Discount payments",

        // Exams
        "Imtixon olish jadvali": "Exam Schedule",
        "Qidiriv...": "Search...",
        Baholanmagan: "Not Graded",
        Guruhi: "Group",
        "Imtixon oyi": "Exam Month",
        "Imtihon so'rovi": "Exam Request",
        "Imtixon sana": "Exam Date",
        "Baholangan sana": "Graded Date",
        "Yosh turi": "Age Type",
        "O'qituvchilari": "Teachers",
        "Imtixon natijalarini kiritish": "Enter Exam Results",
        "Ismi va familyasi": "First and Last Name",
        Raqami: "Number",
        "O'tish ballarini kiriting": "Enter Passing Scores",
        "Qidiruvni kiriting!": "Enter Search!",
        "A'loga o'tish ball": "Excellent Passing Score",
        "O'rtaga o'tish ball": "Average Passing Score",
        "Yosh turiga ko'ra talabalar statistikasi":
          "Student Statistics by Age Type",
        Yillar: "Years",
        "Yosh guruhi": "Age Group",
        "Guruhlar soni": "Number of Groups",
        "O'quvchi talabalar": "Student Pupils",
        "Faol o’quvchilar": "Active Students",
        "Sababli dars qoldirganlar": "Excused Absentees",
        "Sababsiz dars qoldirganlar": "Unexcused Absentees",
        "Guruhdan chiqarilgan talabalar": "Students Removed from Group",
        "Qora ro’yxatga tushganlar": "Blacklisted",
        "Guruhlarni tartiblanish jadvali": "Group Arrangement Schedule",
        "Asosiy o'qituvchi": "Main Teacher",
        "Dars kuni/soat": "Class Day/Hour",
        "Yakunlangan oy": "Completed Month",
        "Boshlangan sana": "Start Date",
        "Yakunlangan sana": "End Date",
        "Jami o’quvchilar": "Total Students",
        "Dars qoldirgan o’quvchilar": "Students Who Missed Class",
        "Chetlatilgan o'quvchilar": "Expelled Students",
        "Bino va xonalarning bandlik jadvali":
          "Building and Room Occupancy Schedule",
        "Bino va xonalar": "Buildings and Rooms",
        Dushanba: "Monday",
        Seshanba: "Tuesday",
        "Binoda xonalar mavjud emas!": "No Rooms Available in the Building!",
        "O'quvchilarni boshqa guruhga olib o'tish jadvali":
          "Schedule for Transferring Students to Another Group",
        "O'quvchilarning F.I.Sh": "Students' Full Name",
        Sanasi: "Date",
        Yoshi: "Age",
        "Avvalgi guruh raqami": "Previous Group Number",
        "Avvalgi guruh darajasi": "Previous Group Level",
        "Avvalgi guruh oy": "Previous Group Month",
        "Avvalgi o'qituvchining F.I.Sh": "Previous Teacher's Full Name",
        "Yangi guruh raqami": "New Group Number",
        "Yangi guruh darajasi": "New Group Level",
        "Yangi guruh oy": "New Group Month",
        "Yangi o'qituvchining F.I.Sh": "New Teacher's Full Name",
        "Yosh turiga ko'ra talabalar o'zlashtirishi":
          "Student Achievement by Age Type",
        Guruhlar: "Groups",
        "O'quvchi talabalar": "Student Pupils",
        "A'lochilar": "Excellent Students",
        "O’rtacha o’zlashtirganlar": "Average Achievers",
        "Passiv o’zlashtirganlar": "Passive Achievers",
        "O'qituvchilar": "Teachers",
        Oylar: "Months",
        Guruhlar: "Groups",
        "O'quvchi talabalar": "Student Pupils",
        "A'lochilar": "Excellent Students",
        "Passiv o’zlashtirganlar": "Passive Achievers",
        "O’rtacha o’zlashtirganlar": "Average Achievers",
        Oy: "Month",
        "Guruh darajasiga ko'ra statistikasi": "Statistics by Group Level",
        "O'quvchini boshqa guruhga olib o'tish":
          "Transfer Student to Another Group",
        "O'quvchini izlash": "Search Student",
        "Ism, familiya, telefon yoki ID raqami:":
          "Name, Surname, Phone or ID Number:",
        yosh: "age",
        "Telefon raqami": "Phone Number",
        Qidiring: "Search",
        "F.I.Sh": "Full Name",
        "O'quvchi ma'lumotlari": "Student Information",
        "Tug'ilgan sanasi va yoshi:": "Date of Birth and Age:",
        "O'qiyotgan guruh:": "Current Group:",
        "O'qituvchisi:": "Teacher:",
        "Qatnashmagan darslar soni:": "Number of Missed Classes:",
        "O'qimoqchi bo'layotgan guruhni topish": "Find Desired Group",
        "Yangi guruhning restor raqami:": "New Group's Register Number:",
        "Guruh ma'lumotlari": "Group Information",
        "Ushbu guruh o'qituvchisi:": "Teacher of This Group:",
        "Guruh haqida ma’lumot:": "Group Information:",
        "Tugallanmagan darslar soni:": "Number of Incomplete Classes:",
        "Telefon raqam:": "Phone Number:",
        "O'tkazish amaliyotni bajarish": "Perform Transfer Operation",
        "O'qituvchi xodimlarni dars jadvaliga asosan ish jarayonlari rejasi":
          "Teacher Staff Work Schedule Based on Class Schedule",
        "Dars turi": "Class Type",
        "Xodimlarning F.I.Sh": "Staff's Full Name",

        // Gruhlar
        "Yangi guruh jurnalini ochish va sozlash":
          "Create and Set Up New Group Journal",
        "Barcha guruhlar haqida": "About All Groups",
        Jami: "Total",
        nafar: "number",
        "Ma'lumot olib bolmadi": "Could not retrieve data",
        "Barcha guruhlarga qaytish": "Return to All Groups",
        "Imtixon yaqinlashgan guruhlar": "Groups Approaching Exams",
        "Guruh raqami": "Group Number",
        "O'qituvchining ismi": "Teacher's Name",
        "Dars kuni/soat": "Lesson Day/Time",
        "Oylik dasrlar": "Monthly Lessons",
        "Bino/xona": "Building/Room",
        "Dars uslubi": "Lesson Style",
        "Guruh tarkibi": "Group Composition",
        "Guruhni boshqarish": "Manage Group",
        "Guruhni faollashtirish": "Activate Group",
        "Guruhni muvaffaqiyat yakunlash": "Successfully Complete Group",
        "Kursni tanlang!": "Select Course!",
        "Kurs davomiyligini kiriting!": "Enter Course Duration!",
        "Guruh turini kiriting!": "Enter Group Type!",
        "Dars turini tanlang!": "Select Lesson Type!",
        "Dars vaqtini kiriting!": "Enter Lesson Time!",
        "Boshlanish sanasini kiriting!": "Enter Start Date!",
        "O'ituvchini tanlang!": "Select Teacher!",
        "Filial nomini tanlang!": "Select Branch Name!",
        "Xona tanlang!": "Select Room!",
        "Yangi guruh tashkil etish": "Create New Group",
        "Guruh turi": "Group Type",
        "Kurs davomiyligi": "Course Duration",
        oy: "month",
        "Boshlanish oyi": "Start Month",
        "Guruh holati bo'yicha": "By Group Status",
        "Oylar bo'yicha": "By Months",
        "Fanlar bo'yicha saralash": "Sort by Subjects",
        "Guruh turi bilan saralash": "Sort by Group Type",
        "Dars kunlari bilan saralash": "Sort by Lesson Days",
        "Binolar bilan saralash": "Sort by Buildings",
        "Guruh statusi": "Group Status",
        "Keyingi bosqichga o'tkazish": "Move to Next Stage",
        "To'xtatish / Yakunlash": "Pause / Complete",
        Sozlash: "Settings",
        "Imtixon so'rovi": "Exam Request",
        "Yangi oy": "New Month",
        "O'tkazish": "Move",
        "Yangi oydan belgilash": "Set from New Month",
        "Guruh faoliyati to'xtatildi": "Group Activity Stopped",
        "Guruh keyingi oyga o'tkazildi": "Group Moved to Next Month",
        "Keyingi oyi": "Next Month",
        "Guruh faollashtirilsinmi?": "Activate Group?",
        Tasdiqlash: "Confirm",
        "Ha,tasdiqlayman": "Yes, I Confirm",
        "Guruh muvaffaqiyat yakunlansinmi?": "Successfully Complete Group?",
        "Guruh ma'lumotlari yangilandi o'tkazildi":
          "Group Data Updated and Moved",
        "Avvalgi oylar yopilgan": "Previous Months Closed",
        "Guruh tarkibi": "Group Composition",
        "O'qituvchi": "Teacher",
        "Dars kunlari": "Lesson Days",
        "Ma'qul dars vaqti": "Preferred Lesson Time",
        "Boshlanish sanasi": "Start Date",
        "Bino va filial": "Building and Branch",
        "Yordamchi o'qituvchi": "Assistant Teacher",
        "O'quvchilar": "Students",
        Xona: "Room",
        baholash: "Assessment",
        "Guruhni butunlay yopish": "Completely Close Group",
        "Imtixon vaqtini belgilang!": "Set Exam Time!",
        "Imtixon so'rovi qabul qilindi": "Exam Request Accepted",
        "Ushbu oy": "This Month",
        "Imtixon olinish sanasi": "Exam Date",
        "Guruhni vaqtincha to'xtatib turish": "Temporarily Pause Group",
        "Hafta kunlari": "Weekdays",
        "Dars vaqtlari": "Lesson Times",
        "Bo'sh xonalar": "Available Rooms",

        "Davomat jurnali": "Attendance Journal",
        "Video darsga o'tish": "Switch to Video Lesson",
        "Ism familyasi": "Name and Surname",
        "ID raqami": "ID Number",
        Imtihon: "Exam",
        "O'rtacha ball": "Average Score",
        Chegirma: "Discount",
        "To'lov holati": "Payment Status",
        "Darsga sababli kelmadi": "Absent with Reason",
        "Darsga sababsiz kelmadi": "Absent without Reason",
        "Darsga keldi": "Present",
        Imzo: "Signature",
        "Sababli kelmadi": "Absent with Reason",
        "Sababsiz kelmadi": "Absent without Reason",
        "Avvalgi oy": "Previous Month",
        "Keyingi oy": "Next Month",
        "O'quvchi haqida!": "About the Student!",
        "Ushbu O'quvchi guruhdan chiqarilsinmi ?":
          "Remove this Student from the Group?",
        "O'quvchi haqida": "About the Student",
        Izoh: "Comment",
        "Qayta bo'glanish": "Reconnect",
        "Guruhdan olish": "Remove from Group",
        "Talabaga ma’qul o’qish kunlari": "Preferred Study Days for Student",
        "Gruppani yoshga doir holati": "Age Status of the Group",
        "Yashash joyi manzili": "Residential Address",
        "ID-kodi": "ID Code",
        "Qabulga yozilgan sanasi": "Enrollment Date",
        "Telefon raqamlari": "Phone Numbers",
        "Passport seriyasi va raqami": "Passport Series and Number",
        "Taqdim qilgan hujjat turi": "Type of Submitted Document",
        "Ortga qaytish": "Go Back",
        Aloqa: "Contact",
        "Bugungi davomat": "Today's attendance",
        "O'quvchining ismi familyasi": "Student's full name",
        "ID raqam": "ID Number",
        "Kelmagan o'quvchilar": "Absent Students",
        "Uyga vazifalar": "Homeworks",
        "Sabablimi?": "With Reason?",
        "Yo'q": "No",
        Belgilash: "Mark",

        // Students
        "O'quvchi va talabalarni ro'yhatga olish": "Student Registration",
        "Ijobiy aloqa": "Positive Communication",
        Guruhsizlar: "Ungrouped",
        "Salbiy aloqa": "Negative Communication",
        "O'quvchining F.I.O": "Student's Full Name",
        "ID-kodi": "ID Code",
        "Talabaning yoshi": "Student's Age",
        "Guruh turi": "Group Type",
        Fani: "Subject",
        "O'qiyotgan guruhi": "Current Group",
        "O'qituvchi": "Teacher",
        "Dars vaqti": "Lesson Time",
        "Bilim darajasi": "Knowledge Level",
        "Filial va bo'lim": "Branch and Department",
        "O'quvchining manzili": "Student's Address",
        "Telefon nomeri": "Phone Number",
        Maktabi: "School",
        Xodim: "Employee",
        "Aloqa natijasi": "Communication Result",
        Ismi: "First Name",
        Izoh: "Comment",
        "Oylikni o'zgartirish": "Change Salary",
        "Ishdan olish": "Dismiss",
        "Ishga olish": "Hire",
        "O'quvchi kiritildi": "Student Entered",
        "Bino va filial": "Building and Branch",
        "Biz haqimizda qayerdan eshitgan": "Where Did You Hear About Us",
        "O'tiladigan fanlar nomi": "Names of Subjects to be Taught",
        "O'quvchining ismini kiriting!": "Enter Student's First Name!",
        "Tug'ilgan sanasini kiriting!": "Enter Date of Birth!",
        "Guruh turini tanlang": "Select Group Type",
        "Dars kunini tanlang": "Select Lesson Day",
        "O'tiladigan fanni tanlang": "Select Subject to be Taught",
        "Dars vaqtini tanlang": "Select Lesson Time",
        "O'quvchining yashash manzilini kiriting!": "Enter Student's Address!",
        "Xodim uchun prol kiriting!": "Enter Password for Employee!",
        "Raqamini to'liq kiriting!": "Enter Full Number!",
        "O'quvchining  telefon raqamini kiriting!":
          "Enter Student's Phone Number!",
        "Kamida 8 ta belgidan iborat parol kiriting!":
          "Enter a Password with at Least 8 Characters!",
        "Reklama orqali": "Through Advertisement",
        "Telegramm orqali": "Through Telegram",
        Familyasi: "Last Name",
        "Ushbu fanni oldin o'qiganmi?":
          "Has the Student Studied This Subject Before?",
        "O'qimoqchi bo'lgan o'qituvchi": "Teacher They Want to Study With",
        "Shaxsni tasdiqlovchi hujjat turi": "Document Type",
        Passport: "Passport",
        "Otasining ismi": "Father's Name",
        "Qancha vaqt o'qigan?": "How Long Have They Studied?",
        "Ma'qul dars kuni": "Preferred Lesson Day",
        "Passport seriyasi va raqami": "Passport Series and Number",
        "Ma'qul dars vaqti": "Preferred Lesson Time",
        "O'quvchining holati": "Student's Status",
        "Telefon raqami": "Phone Number",
        Metirka: "Metric",
        "O'quvchining bilim darajasi": "Student's Knowledge Level",
        "O'quvchi ma'lumotlari yangilandi!": "Student Information Updated!",
        Ha: "Yes",
        Taxrirlash: "Edit",
        Aloqa: "Communication",
        "Guruhga qaytarish": "Return to Group",
        "O'chirish": "Delete",
        "Aloqa turini kiriting!": "Enter Communication Type!",
        "O'quvchi ma'lumotlari yangilandi": "Student Information Updated",
        "Aloqa natijasi": "Communication Result",
        "Fanlar bo'yicha saralash": "Sort by Subjects",
        "Guruh turi bilan saralash": "Sort by Group Type",
        "Dars kunlari bilan saralash": "Sort by Lesson Days",
        "Dars vaqtlari bilan saralash": "Sort by Lesson Times",
        "Binolar bilan saralash": "Sort by Buildings",
        "Xodim bilan saralash": "Sort by Employee",
        "O'quvchilar bilim darajasi": "Students' Knowledge Level",
        Xodimlar: "Employees",
        Binolar: "Buildings",
        "Dars vaqtlari": "Lesson Times",
        "Dars kunlari": "Lesson Days",
        "Guruh turlari": "Group Types",
        Fanlar: "Subjects",

        // Staff
        staff_title: "Staff and Teacher Registration",
        thead_title: "Employee's Full Name",
        thead_id: "ID Code",
        "Ro’yxatdan o’tgan sana": "Registration Date",
        "Tug’ilgan sana": "Date of Birth",
        "Hujjat turikumi": "Document Type",
        "Hujjat seriya va raqami": "Document Series and Number",
        "Lavozimi va xizmat vazifasi": "Position and Job Responsibilities",
        "Ish faoliyati joyi": "Workplace",
        "Telefon raqam": "Phone Number",
        Oylik: "Salary",
        "Qabul qilingan va bekor qilingan sana":
          "Date of Hiring and Termination",
        Sababi: "Reason",
        Lavozimlar: "Positions",
        Filiallar: "Branches",
        "Xodimni ismini kiriting!": "Enter employee's first name!",
        "Belgilar soni ortiqcha!": "Too many characters!",
        "Xodimni familiyasini kiriting!": "Enter employee's last name!",
        "Xodimni otasini ismi kiriting!": "Enter employee's father's name!",
        "Xodim jinsini tanlang!": "Select employee's gender!",
        "Tug'ilgan sanasini kiriting!": "Enter date of birth!",
        "Hujjat turini tanlang!": "Select document type!",
        "Passport raqamini kiriting!": "Enter passport number!",
        "Telefon raqamini kiriting!": "Enter phone number!",
        "Bino turini tanlang!": "Select building type!",
        "Buyuruq va mehnat shartnomasi kiritilsin!":
          "Enter order and employment contract!",
        "Kamida 8 ta belgidan iborat parol kiriting!":
          "Enter a password with at least 8 characters!",
        "Xodim uchun parol kiriting!": "Enter password for employee!",
        Ismi: "First Name",
        "Shaxsni tasdiqlovchi hujjat turi": "Document Type",
        Passport: "Passport",
        "ID-Card": "ID-Card",
        Familyasi: "Last Name",
        Jinsi: "Gender",
        Erkak: "Male",
        "Tug'ilgan sanasi": "Birth date",
        Ayol: "Female",
        "Passport seriyasi va raqami": "Passport series and number",
        "Otasining ismi": "Father's Name",
        "Buyuruq va mehnat shartnomasi": "Order and Employment Contract",
        Nomeri: "Number",
        "Ish faoliyat joyi": "Workplace",
        "Xodimning lavozimi": "Employee's Position",
        "File yuklash": "Upload File",
        "Bekor qilish": "Cancel",
        Saqlash: "Save",
        Parol: "Password",

        // branches
        branches_title: "Enter Buildings and Branches",
        branch_name: "Name of Center and Branches",
        branch_address: "Location Address",
        room_count: "Number of Available Classrooms",
        short_name: "Short Name or Nickname",
        created_date: "Date Created",
        branch_rooms: "Number of Rooms in Building",
        room_number: "Room Number",
        room_name: "Room Name",
        room_teacher: "Responsible Teacher",
        is_lesson_room: "Lesson room",
        yes: "Yes",
        no: "No",
        required_address: "Enter address!",
        address_limit: "Address name should be less than 200 characters!",
        required_branch_name: "Enter Building Name",
        branch_name_limit: "Building name should be less than 100 characters!",
        required_short_name: "Enter Short Name!",
        short_name_limit: "Short name should be less than 50 characters!",
        required_branch_room_count:
          "Enter the number of rooms in the building!",
        required_room_name: "Enter the room name!",
        required_staff: "Staff is required!",
        success_branch_add: "Building added",
        success_branch_update: "Building type updated!",
        address_placeholder: "City, Street, House",
        branch_name_placeholder: "First Main Practice Center",
        short_name_placeholder: "Name, Nickname",
        // config-template
        config_template_title: "Entering the type and time of the lesson",
        add_btn: "Add",
        months: "Months",
        months_payment: "Monthly payment",
        payment_month_amount: "Monthly payment amount",
        branch: "Branches",
        subject: "Subjects",
        group_type: "Group types",
        lesson_plan: "Weekly lesson schedule",
        course_duration: "Program duration",
        lesson_count: "Number of lessons",
        enter_course: "Enter course hours and types",
        enter_payment: "Enter monthly payments",
        payment_month: "Payment month",
        payment_price: "Monthly payment",
        required_subject: "Subject selection required",
        required_branch: "Branch selection required",
        required_group: "Select group type",
        required_course_duration: "Enter total program duration",
        required_lesson_plan: "Enter weekly lesson count",
        only_number: "Enter a number",
        only_string: "Enter text",
        required_month: "Enter the month",
        required_month_price: "Enter monthly payment",
        success_open_course: "New course opened",
        delete: "Delete",
        cancel: "Cancel",
        submit: "Save",
        edit: "Edit",
        confirm: "Confirm",
        agree_delete: "Are you sure you want to delete the selected data?",
        textbooks: "Textbooks",
        others: "Others",
        SMS: "SMS notification",
        expenditure_reports: "Expenditure reports",
        weekly_reports: "Weekly income reports",
        finished_students: "Finished students",
        monthly_reports: "Monthly income reports",
        daily_reports: "Daily income reports",
        reports_with_agegroup: "Group report by age type",
        staff_statistics: "Staff statistics",
        income_reports: "Income reports",
        active_passive_readers: "List of active and passive readers",
        missed_class: "Students who missed classes",
        exam_failed_students: "Exam failed students",
        excluded_from_group: "Excluded from the group",
        discount_reports: "Discount reports",
        reception_reports: "Reception reports",
        reports_header: "Reaports section",
        header_exit: "Exits",
        header_settings: "Settings",
        header_profile: "Profile",
        home_tasks: "Tasks",
        home_income_reports: "Income reports",
        home_all_reports: "All reports",
        home_paying_employees: "Paying employees",
        home_list_of_salary: "List of salary and advance payments",
        home_list_of_expenses: "List of expenses",
        home_expenses: "Expenses",
        home_list_of_students_in_debt: "List of students in debt",
        home_icome_cash_history: "Payment history",
        home_icome_cash: "Payment acceptance",
        home_teachers_availability: "Teachers occupancy schedule",
        home_rooms_availability: "Rooms occupancy schedule",
        home_statistics_by_lesson_month: "Group level statistics",
        home_acquisition_by_age_group: "Student acquisition by age group",
        home_statistics_by_age_group: "Student statistics by age group",
        home_groups_level_up: "Groups level up",
        home_student_transfer_list: "List of transferred students",
        home_student_transfer: "Transfer the student to another group",
        home_exams: "Exam schedule",
        home_groups: "Groups",
        home_students: "Students",
        home_teachers: "Teachers",
        home_branches: "Building and Branches",
        home_courses: "Courses",
        home_reports_module: "Reports section",
        home_cash_config: "Cash control",
        home_groups_statistics: "Group statistics",
        home_groups_manage: "Manage groups",
        home_registration: "Registration",
        home_config: "Configuration",
      },
    },
  },
});

export default i18n;
