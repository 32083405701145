import CommonHeading from "../../../../Components/CommonHeading";
import Selector from "./Select";
import TableHead from "./TableHead";
import { useFormik } from "formik";
import * as Yup from "yup";
import Searchbar from "../../../../Components/Searchbar";
import { useCallback } from "react";
import FormControlLabelPosition from "./CheckBox";
import { useStudentsBillingStore } from "../../../../Store/CashControlStore/StudentsBillingStore";
import { useMemo } from "react";

import { useTranslation } from "react-i18next";
export default function Heading() {
  const {
    loadItems,
    updateParams,
    teachers_list,
    branches_list,
    queryParams,
    setLoading,
  } = useStudentsBillingStore();
  const { t } = useTranslation();

  const searchAction = useCallback(async (payload) => {
    updateParams({ search_query: payload.term, page: 1 });
    setLoading(true);
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        page: 1,
        search_query: payload.term,
      }).toString()
    );
    setLoading(false);
  });

  const initialValues = {
    term: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue("term", e.target.value);
    searchAction({
      term: e.target.value,
    });
  };

  const optionsStaffs = useMemo(() => {
    return (
      teachers_list?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [teachers_list]);

  const optionsBranchs = useMemo(() => {
    return (
      branches_list?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [teachers_list]);

  return (
    <CommonHeading
      topItems={
        <div className="flex_wrap--header">
          <Searchbar
            value={formik.values.term}
            handleChange={(e) => handleChange(e)}
            handleSubmit={formik.handleSubmit}
          />
          <Selector options={optionsStaffs} property={t("O'qituvchi")} />
          <Selector options={optionsBranchs} property={t("Filial")} />
          <FormControlLabelPosition />
        </div>
      }
      tableHead={<TableHead />}
    />
  );
}
